.project {
    position: relative;
    display: flex;
    flex-direction: column;
    background-size: cover;
    background-position: center;
    min-height: 100vh;
    width: 100%;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        background-color: var(--primary-color);
    }

    .project-content {
        flex: 1;
        padding: 1em;
        padding-top: 3em;
        display: grid;
        grid-template-columns: 2fr 3fr;
        justify-content: center;
        z-index: 1;
        align-items: center;

        @media screen and (max-width: 60em) {
            flex-direction: column;
            grid-template-columns: 1fr;
            grid-template-rows: auto auto;

            .text-wrapper {
                grid-column: 1 !important;
                grid-row: 2 !important;
            }

        }

        h1 {
            font-weight: bold;
            display: flex;
            align-items: center;

            a {
                margin-left: .5em;
                height: 1em;

                img {
                    height: 100%;
                    transition: transform 0.3s ease-in-out; /* Add transition for smooth animation */
                }

                img:hover {
                    transform: scale(1.1); /* Scale the image up on hover */
                }
            }
        }

        p {
            // border-radius: 0.625em;
        }

        .text-wrapper {
            grid-column: 2;
            min-width: 50%;
            padding-left: 1em;
            display: flex;
            align-items: left;
            justify-content: center;
            flex-direction: column;
            overflow: hidden;

            > * {
                opacity: 0; /* Set initial opacity to 0 */
                animation: fadeInProjectInformation 0.5s forwards; /* Apply fadeIn animation */
            }

            @for $i from 1 through 10 {
                > *:nth-child(#{$i}) {
                    animation-delay: #{($i - 1) * 0.2}s; /* Set different animation delays */
                }
            }
        }

        .carousel-wrapper {
            // width: 100%;
            // flex-grow: 2;
            grid-column: 1;

            //fade it in from left:
            // animation: fadeInProjectCarousel 0.5s forwards;
            // animation-delay: .5s;
            // opacity: 0;

            // &:has(.carousel-loader){
            //     width: 40em;
            // }
        }
    }
}

// @keyframes fadeInProjectCarousel {
//     from {
//         opacity: 0;
//     }
//     to {
//         opacity: 1;
//     }
// }

@keyframes fadeInProjectInformation {
    from {
        opacity: 0;
        transform: translateX(100%); /* Start from the right */
    }
    to {
        opacity: 1;
        transform: translateX(0); /* End at the original position */
    }
}