.projects{
    padding: 1em;
    background-color: var(--primary-color);

    h2{
        margin: 0;
        text-align: center;
        margin-top: 1.33333em;
    }

    .projects-wrapper{
        margin: 1em 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1em;

      

        &.visible .card{
            opacity: 1;
        }

        @for $i from 1 through 20 {
            &.visible .card:nth-child(#{$i}) {
                transition-delay: #{($i - 1) * 0.1}s;
            }
        }

        .card {
            flex: 1 1 48%; // Adjusted to ensure two cards per row
            // max-width: 48%; // Ensures that the cards do not exceed 48% of the container width
            min-width: 24em;
            height: 20em;
            border-radius: 0.625em;
            overflow: hidden;
            cursor: pointer;
            position: relative;
            color: var(--secondary-text-color);
            box-shadow: 0 0 1em 0 var(--box-shadow);
            opacity: 0;
            transition: opacity .5s ease-in-out;
            user-select: none;

            @media (max-width: 550px){
              min-width: unset;
            }
            
            @media (prefers-color-scheme: dark) {
              border: 2px solid var(--inverted-primary-color);
            }
           
            img {
              position: absolute;
              object-fit: cover;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              opacity: 0.9;
            }
          
            h2 {
              position: absolute;
              inset: auto auto 1em 20px;
              margin: 0;
              transition: inset .3s .3s ease-out;
              text-transform: uppercase;
              text-align: left;
            }
            
            p, a {
              position: absolute;
              opacity: 0;
              max-width: 80%;
              transition: opacity .3s ease-out;
            }
            
            p {
              inset: 5em auto auto 20px;
            }
            
            a {
                inset: auto auto 2.5em 20px;
                color: var(--secondary-text-color);
                &:hover{
                    color: var(--special-color);
                }
            }.card-background{
                position: absolute;
                width: 100%;
                height: 100%;
                transition: background-color .3s ease-out;
                box-shadow: inset 0 -10em 2em -5.5em rgba(0,0,0,0.75);

            }
            &:hover .card-background, &:has(*:focus) .card-background{
                background-color: rgba(0, 0, 0, 0.6);
            }
            
            &:hover h2, &:has(*:focus) h2 {
              inset: auto auto 10em 1em;
              transition: inset .3s ease-out;
            }
            
            &:hover p, &:has(*:focus) p, &:hover a, &:has(*:focus) a {
              opacity: 1;
              transition: opacity .5s .1s ease-in;
            }

            &:hover img, &:has(*:focus) img {
              transition: opacity .3s ease-in;
              opacity: 1;
              filter: blur(.2em);
                -webkit-filter: blur(.2em);
            }
          
        }
    }
}
