.arrow-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
}

.wiggle-wrapper{
	cursor: pointer;
	height: 3em;
	&:has(.arrow.wiggle){
		animation: wiggle 3s linear infinite;
	}

	&:hover .arrow::after, .arrow:focus::after {
		transform: rotate(45deg);
		border-color: var(--special-color);
		height: calc(120% - .2em);
	}
	&:hover .arrow::before, .arrow:focus::before {
		border-color: var(--special-color);
		transform: scale(.8);
		
	}
}

div.arrow {
	width: 1.5em;
    height: 1.5em;
	// margin-left: -.3em;
	margin-top: 1em;
	box-sizing: border-box;
	left: 50%;
	top: 50%;
	transform: rotate(-45deg);

	&.bottom{
		transform: rotate(135deg);
	}	

	&:focus{
		outline: var(--special-color) solid var(--outline-width);
		border-radius: 0.125em;
		transition: all 0.1s;
		outline-offset: var(--outline-offset);
	}
	
	&::before {
		content: '';
		width: calc(100% - .2em);
    	height: calc(100% - .2em);
		border-width: .2em .2em 0 0;
		border-style: solid;
		border-color: var(--primary-text-color);
		transition: .3s ease;
		display: block;
		transform-origin: 100% 0;
	}

	
	&:after {
		content: '';
		float: left;
		position: relative;
		top: calc(-100% + .1em);
		width: calc(100% - .2em);
    	height: calc(100% - .2em);
		border-width: 0 .2em 0 0;
		border-style: solid;
		border-color: var(--primary-text-color);
		transform-origin: calc(100% - .1em) 0;
		transition:.3s ease;
	}
	
	
	
}
@keyframes wiggle {
    0%, 100% {
        transform: translateX(0);
    }
    5% {
        transform: translateX(-3px);
    }
    10% {
        transform: translateX(5px);
    }
    15% {
        transform: translateX(-5px);
    }
	20% {
		transform: translateX(3px);
	}
	25% {
		transform: translateX(0);
	}
}