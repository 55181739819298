body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: rgba(255, 255, 255, 0.9);
  --inverted-primary-color: rgba(0, 0, 0, 0.9);
  --secondary-text-color: hsl(0, 0%, 100%);
  --primary-text-color: #3f3f3f;
  --inverted-primary-text-color: #fff;
  --box-shadow: rgba(0, 0, 0, 0.4);

  --special-color: #3a77c1;
  --special-color-inactive: rgb(31, 65, 106);

  --outline-offset: 2px; /*0.125em;*/
  --outline-width: 2px; /*0.125em;*/


  @media (prefers-color-scheme: dark) {
    --primary-color: rgba(0,0,0, 0.9);
    --inverted-primary-color: rgba(255, 255, 255, 0.9);
    --primary-text-color: #fff;
    --inverted-primary-text-color: #3f3f3f;
    --box-shadow: rgba(255,255,255, 0.4)
  }
}

.fancy-link {
  text-decoration: none;
  position: relative;
  color: var(--primary-text-color);
}
.fancy-link:after{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  
  width: 100%;
  height: 0.125em;
  background: currentColor;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: right;
  transform-origin: right;
  transition: -webkit-transform .25s ease-in;
  transition: transform .25s ease-in;
  transition: transform .25s ease-in,-webkit-transform .25s ease-in;
}
a.fancy-link:after{
  bottom: calc(-1 * var(--outline-offset));
}
.fancy-link:hover:after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transform-origin: left;
  transform-origin: left;
}
.fancy-link:hover {
  /* color: #e9e9e9; */
  transition: .3s ease-out;
  color: var(--special-color);
}
a:focus{
  outline: var(--special-color) solid var(--outline-width);
  border-radius: 0.125em;
  transition: all 0.1s;
  outline-offset: var(--outline-offset);
}
