.navigation{
    position: fixed;
    z-index: 100;
    top: 0;
    // left: 50%;
    width: 100%;
    // width: max-content;
    // transform: translateX(-50%);
    animation: fadeInNavigation 1s forwards;
    text-align: center;
    // transition: all 0.5s;
    border-bottom: 2px solid;
    border-color: transparent;


    &.project-type{
        top: 0;
        background-color: var(--primary-color);
        border-color: var(--special-color);
    }



    // @media (max-width: 550px){
    //     left: 0;
    //     transform: none;
    // }

    .background{
        background-color: var(--primary-color);
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    @keyframes fadeInNavigation {
        from {
            opacity: 0;
            top: -2rem;
        }
        to {
            opacity: 1;
            top: 0;
        }
    }

    .navigation-button{
        background-color: transparent;
        border: none;
        color: var(--primary-text-color);
        padding: 0.25rem;
        margin: 0.5rem;
        cursor: pointer;
        font-weight: bold;
        display: inline-block;
    
        &:hover{
            color: var(--special-color) !important;
        }
    
        &.active{
           color: var(--special-color) !important;
        }

        &:focus{
            outline: var(--special-color) solid var(--outline-width);
            border-radius: 0.125em;
            transition: all 0.1s;
            outline-offset: var(--outline-offset);
        }
    }
}

