.language-selector{
    position: fixed;
    z-index: 200;
    top: 0;
    animation: fadeInLanguageSelector 1s forwards;

    @media (max-width: 550px){
        top: unset;
        bottom: 0;

        background-color: var(--primary-color);
        border-top-left-radius: .5em;
        .language-button{
            color: var(--primary-text-color) !important;
        }
        span{
            color: var(--primary-text-color) !important;
        }
        
    }

    @keyframes fadeInLanguageSelector {
        from {
            opacity: 0;
            right: -10%
            // margin-left: -10%;
        }
        to {
            opacity: 1;
            right: 0;
            // margin-left: 10%;
        }
    }

    

    .language-button{
        background-color: transparent;
        border: none;
        color: var(--primary-text-color);
        padding: 0.25rem;
        margin: 0.5rem;
        cursor: pointer;
        font-weight: bold;
    
        &:hover{
            color: var(--special-color) !important;
        }
    
        &.active{
           color: var(--special-color) !important;
        }

        &:focus{
            outline: var(--special-color) solid var(--outline-width);
            border-radius: 0.125em;
            transition: all 0.1s;
            outline-offset: var(--outline-offset);
        }
    }
}

