.carousel-wrapper{
    display: flex;
    align-items: center;
    position: relative;

    .carousel-loader{
        position: absolute;
        width: 100%;
    }

    &.no-flex{
        display: block;
        align-items: unset;

        .carousel-root{
            height: 100% !important;
        }
    }

    .carousel-root{
        // height: min-content;
        // max-height: 30em;
        // aspect-ratio: 16/9;
        height: unset;
        box-shadow: 0 0 1em 0 var(--box-shadow);
        // background-color: var(--inverted-primary-color);
        background-color: black;
        border-radius: 0.625em;
        overflow: hidden;
        opacity: 0;

        &.show{
            animation: fadeInProjectCarousel 0.5s forwards;
        }


        @media (prefers-color-scheme: dark) {
            border: 2px solid var(--inverted-primary-color);
        }

        .carousel {
            width: 100%;
            height: 100%; /* Ensure the carousel takes up the full height of its container */
            display: flex;
            flex-direction: column;
        }
        
        .carousel-item {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%; /* Ensure the item takes up the full height of the carousel */
            position: relative;
            flex: 1; /* Allow the item to grow and shrink as needed */
        }
        
        .carousel-item > * {
            max-height: 100%; /* Ensure the media does not exceed the height of the item */
            max-width: 100%; /* Ensure the media does not exceed the width of the item */
            object-fit: contain; /* Scale the media to fit within the item while maintaining aspect ratio */
        }
        
        .carousel-media {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain; /* Ensure the media scales down if it's too large */
        }
        
        .slider-wrapper {
            display: flex;
            flex: 1; /* Allow the slider wrapper to grow and shrink as needed */
        }

        .control-arrow{
            background: rgba(0, 0, 0, 0.5);

            &:focus-visible{
                background: rgba(0, 0, 0, 0.7);

                &::before{
                outline: var(--special-color) solid var(--outline-width);
                border-radius: 0.125em;
                transition: all 0.1s;
                outline-offset: var(--outline-offset);
                }
            }
            &:hover{
                background: rgba(0, 0, 0, 0.7);
            }
        }
        .control-dots .dot{
            &:focus-visible{
                outline: var(--special-color) solid var(--outline-width);
                transition: all 0.1s;
                outline-offset: var(--outline-offset);
                opacity: 1;
            }
        }
    }
}

@keyframes fadeInProjectCarousel {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
