.cv{
    padding: 1em;
    background-color: var(--primary-color);
    overflow: hidden;

    .button-wrapper{
        display: flex;
        justify-content: center;
        gap: 1em;
        margin: 2em 0;
        flex-wrap: wrap;

        > *{
            opacity: 0;
            transition: opacity .3s ease-in-out;

            button{
                min-width: 12em;
            }

        }

        &.visible > * {
            opacity: 1;
        }

        @for $i from 1 through 10 {
            &.visible > *:nth-child(#{$i}) {
                transition-delay: #{($i - 1) * 0.2}s;
            }
        }

    }

    .lifeline-wrapper{
        display: grid;
        grid-template-columns: 50% 50%;

        @media (max-width: 550px) {
            grid-template-columns: 100%;
        }

        &.flash {
            animation: flashLifeline 0.2s ease-in-out;
        }

        .cv-point{
            h2{
                margin: 0;
            }
        }
        .slide-in-left{
            text-align: right;
            li{
                text-align: left;
            }
            border-right: 4px solid var(--special-color);
            margin-right: -2px;
            .dot{
                right: -7px;
            }
            grid-column: 1;
        }
        .slide-in-right{
            border-left: 4px solid var(--special-color);
            margin-left: -2px;
            grid-column: 2;

            .dot{
                left: -7px;
            }

            @media (max-width: 550px) {
                text-align: right;
                grid-column: 1;

                li{
                    text-align: left;
                }
                border-left: unset;
                border-right: 4px solid var(--special-color);
                margin-left: unset;
                margin-right: -2px;
                .dot{
                    left: unset;
                    right: -7px;
                }
            }
        }

        .slide-in-left, .slide-in-right {
            padding: 1em;
            opacity: 0;
            transition: transform 1s ease-out, opacity 1s ease-out;
            position: relative;
            .dot{
                width: 10px;
                height: 10px;
                position: absolute;
                bottom: -5px;
                background-color: var(--special-color);
                border-radius: 100%;
            }

            @media (max-width: 550px) {
                &::after{
                    content: '';
                    position: absolute;
                    bottom: 2px;
                    // right: 10px;
                    right: 0;
                    transform: translateY(100%);
                    width: calc(100%);
                    height: 4px;
                    background: linear-gradient(to right, transparent 0%, var(--special-color) 70%, var(--special-color) 100%);
                    // background-color: var(--special-color);
                    // border-radius: 100%;
                }
            }
        }
        
        .slide-in-left.start {
            transform: translateX(-100%);
        }
        
        .slide-in-right.start {
            transform: translateX(100%);
            @media (max-width: 550px) {
                transform: translateX(-100%);
            }
        }
        
        .slide-in-left.visible, .slide-in-right.visible {
            opacity: 1;
            transform: translateX(0);
        }
    }
}

@keyframes flashLifeline {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}