
.parallax-container {
    // min-height: 100lvh;
 
    /*  this is where the magic happens:  */
    // background-attachment: fixed;
  
    // background-position: 50% 20%;
    // background-size: cover;
    position: relative;
    overflow: hidden;

    // @for $i from 1 through 10 {
    //     .parallax-image-wrapper:nth-child(#{$i}) {
    //         z-index: -#{(10 - $i)};
    //     }
    // }

    .parallax-image-wrapper{
        height: 100vh;
        position: fixed;
        width: 100%;    
        z-index: -1;
        top: 0;



        


        .parallax-image{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            // object-position: center -14em;
            position: absolute;
            top: 0;
            animation: zoomOut 1s cubic-bezier(0.42, 0, 0.04, 0.99) forwards;
        }

        @keyframes zoomOut {
            0% {
                transform: scale(1.4);
            }
            100% {
                transform: scale(1);
            }
        }
    }
}
.buffer {
    width: 100%;
    min-height: 0.25em;
    background-color: var(--primary-text-color);

    &.shadow {
        box-shadow: 0 0 5em 5em var(--primary-color)
    }    
}