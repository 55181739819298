*{
  font-family: 'WorkSans-Regular', sans-serif;
  /* Add more font-family for fallback */

}
h1, h2, h3, h4, h5, h6{
  font-family: 'WorkSans-ExtraLight', sans-serif;
  /* Add more font-family for fallback */
}
body{
  overflow-x: hidden;
  color: var(--primary-text-color);
  background-color: var(--primary-color);
}