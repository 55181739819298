// Define styles for form elements

.contact{
    position: relative;
    padding: 1em;
    background-color:  var(--primary-color);


    h2{
        margin: 0;
        text-align: center;
        margin-top: 1.33333em;
    }

    form {
        display: flex;
        flex-direction: column;
        max-width: 25em;
        margin: 0 auto;
        padding: 1em 0;
    
        label {
            margin-bottom: 0.5rem;
            font-weight: bold;
        }
    
        input,
        textarea {
            padding: 0.5rem;
            margin-bottom: 1rem;
            border: 1px solid var(--special-color);
            border-radius: 0.5em;
            background-color: var(--primary-color);
            color: var(--primary-text-color);

            &:focus{
                outline: var(--special-color) solid var(--outline-width);
                transition: all 0.1s;
                outline-offset: var(--outline-offset);
              }
        }

        textarea{
            height: 5em;
            resize: vertical;
        }

        > *{
            opacity :0;
            transition: opacity .3s ease-in-out;

        }

        &.visible > *{
            opacity: 1;
        }

        @for $i from 1 through 20 {
            &.visible > *:nth-child(#{$i}) {
                transition-delay: #{($i - 1) * 0.1}s;
            }
        }

    }

    .dialog-background{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--primary-color);
        z-index: 999; // Ensure it is below the dialog but above other elements
        opacity: 0;
        animation: fadeInContactDialog .5s forwards;
    }
    
    // Define styles for dialog
    .dialog {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: var(--primary-color);
        color: var(--primary-text-color);
        padding: 1rem;
        border-radius: .5em;
        z-index: 1000; // Ensure it is above other elements
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid var(--special-color);
        width: min(calc(100% - 2em - 2 * 10px - 2 * 1px), calc(25em - 2em - 2px));
        text-align: center;
        opacity: 0;
        animation: fadeInContactDialog .5s forwards;

        @media (prefers-color-scheme: dark) {
        }
    
        p {
            margin: 0 0 1em 0;
        }
    
        button {
            width: 100%;
        }
    }

    @keyframes fadeInContactDialog {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    
    
}
