footer{
    background-color: var(--primary-color);
    padding: 1em;
    display: flex;
    justify-content: space-between;
    gap: .5em;

    div{
        display: flex;
        // justify-content: center;
        // align-items: center;
        flex-direction: column;
        align-items: baseline;
        justify-content: center;
        flex-basis: 33%;
    }
    .github{
        align-items: start;
    }
    .contact-info{
        align-items: center;
    }
    .copyright{
        align-items: end;
    }

    p{
        margin: 0;
    }
    a{
        height: 2em;
        img{
            height: 2em;
            transition: transform 0.3s ease-in-out; /* Add transition for smooth animation */
        }
    
        img:hover {
            transform: scale(1.1); /* Scale the image up on hover */
        }
    }

    @media screen and (max-width: 50em){
        flex-direction: column;
        div{
            flex-basis: 100%;
            justify-content: center;
            align-items: center !important;
        }
        
    }
}