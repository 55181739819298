.header{
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: end;
    height: 100svh;
    position: relative;

    .animation-wrapper{
        position: absolute;
        bottom: 25%;
        left: 10%;
        overflow: hidden;

        @media (max-width: 1400px){
            font-size: .8em;

        }

        @media (max-width: 1100px){
            font-size: .6em;
        }


        @media (max-width: 800px){
            top: 2.5rem;
            width: 100%;
            left: 0;
            text-align: center;
            // bottom: 100%;
        }
    }

    .info-wrapper{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        row-gap: 1rem;
        background-color: var(--primary-color);
        // box-shadow: 0 0 20px 10px rgb(255, 255, 255,.75);
        padding: 2em;
        //make tghe info-wrapper fade in
        // opacity: 0;
        animation: fadeInNormal 1s forwards;
       
        @keyframes fadeInNormal {
            from {
                transform: translateY(100%);
            }
        }

       

        h1{
            font-size: 3em;
        }
        p{
            font-size: 1.5em;
        }
        h1,p{
            margin: 0;
        }
        @media (max-width: 800px){
            align-items: center;
            animation: fadeInMobile 1s forwards;

        }
        @keyframes fadeInMobile {
            from {
                transform: translateY(400%);
                opacity: 0;
            }
        }
    }
    .arrow-wrapper{
        position: absolute;
        bottom: 1em;
        left: 50%;
        transform: translateX(-50%);
    }
}