.custom-button{
    display: inline-block;
    padding: .5em 1em;
    border: none;
    background-color: var(--special-color);
    color: var(--secondary-text-color);
    font-size: 1em;
    text-align: center;
    text-decoration: none;
    cursor: pointer;    
    // transition: background-color 0.3s ease;
    user-select: none;
    border-radius: .5em;
    border: 1px solid var(--special-color);
    transition: background-color 0.2s ease, color 0.2s ease;

    &.inactive{
        background-color: transparent;
        color: var(--primary-text-color);

        // background-color: var(--special-color-inactive);
    }

    &:hover {
        background-color: var(--special-color);
        color: var(--secondary-text-color);

    }

    &:focus{
        outline: var(--special-color) solid var(--outline-width);
        color: var(--secondary-text-color);
        background-color: var(--special-color);
        outline-offset: var(--outline-offset);
    }

    &[disabled]{
        background-color: var(--special-color-inactive);
        cursor: not-allowed;
    }
    &:has(.loading-holder){
        position: relative;


    }

    .loading-holder{
        font-size: .5em;
        color: var(--secondary-text-color);
        opacity: 0;
        animation: fadeInLoadingHolderButton .5s forwards;

        .sk-circle .sk-child:before{
            background-color: var(--secondary-text-color);

        }
    }
    @keyframes fadeInLoadingHolderButton {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}